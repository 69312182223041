import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
// import store from './utils/js/store/index.js'

import 'ant-design-vue/dist/antd.less'


import './utils/css/font.css'
import './utils/css/client-font.css'
import './utils/css/new-font.css'
import './utils/css/common.css'

Vue.use(Antd)

Vue.config.productionTip = false

import  router from './utils/js/router';
// import {common} from './utils/js/common.js';
// Vue.mixin(common);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')